import { createAction, props } from '@ngrx/store';
import { TargetAudience } from '../../models';

export const LoadTargetAudiences = createAction('[TargetAudiences] Load TargetAudiences');
export const LoadTargetAudiencesSuccess = createAction(
  '[TargetAudiences] Load TargetAudiences Success',
  props<{ targetAudience: TargetAudience[] }>()
);
export const LoadTargetAudiencesFail = createAction('[TargetAudiences] Load TargetAudiences Fail', props<{ error: any }>());

export const ClearTargetAudiences = createAction('[TargetAudiences] Clear TargetAudiences');
