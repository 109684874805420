import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { CoreModule } from '../core/core.module';
import { CookieNgrxModule } from '@teamfoster/sdk/cookie-ngrx';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { ContentBlockContainerComponent } from './containers/content-block-container/content-block-container/content-block-container.component';
import { ContentBlockDefaultComponent } from './components/content-block-default/content-block-default.component';
import { ImageModule } from '@teamfoster/sdk/image';
import { ButtonModule } from '@teamfoster/sdk/button';
import { IconModule } from '@teamfoster/sdk/icon';

export const moduleExports = [ContentBlockContainerComponent];

@NgModule({
  declarations: [...moduleExports, ContentBlockDefaultComponent],
  imports: [
    CommonModule,
    RouterModule,
    TextUtilityModule,
    CoreModule,
    CookieNgrxModule,
    DictionaryNgrxModule,
    ImageModule,
    ButtonModule,
    IconModule,
  ],
  exports: [...moduleExports],
})
export class ContentBlocksModule {}
