import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromRoot from '../../../store/reducers';
import * as fromTargetAudiences from '../reducers/target-audience.reducer';
import { TargetAudience } from '../../models';

export const getTargetAudienceState = createSelector(
  fromFeature.getLandingState,
  (state: fromFeature.TargetAudienceState) => state.targetAudience
);

export const getTargetAudienceEntities = createSelector(getTargetAudienceState, fromTargetAudiences.getTargetAudiencesEntities);

export const getAllTargetAudiences = createSelector(getTargetAudienceEntities, entities => {
  return Object.keys(entities).map(slug => entities[slug]);
});

export const getOrderedTargetAudiences = createSelector(getAllTargetAudiences, targetAudience => {
  return targetAudience.sort((a, b) => a.order - b.order);
});

export const getTargetAudiencesLoaded = createSelector(getTargetAudienceState, fromTargetAudiences.getTargetAudiencesLoaded);
export const getTargetAudiencesLoading = createSelector(getTargetAudienceState, fromTargetAudiences.getTargetAudiencesLoading);

export const getSelectedTargetAudience = createSelector(
  getTargetAudienceEntities,
  fromRoot.getRouterState,
  (entities, router): TargetAudience => {
    return router.state && entities[router.state.params?.['id'] || router.state.data?.['id']];
  }
);
export const getNextTargetAudience = createSelector(
  getOrderedTargetAudiences,
  fromRoot.getRouterState,
  getSelectedTargetAudience,
  (entities, router, currentAudience): TargetAudience => {
    return entities.indexOf(currentAudience) + 1 < entities.length ? entities[entities.indexOf(currentAudience) + 1] : entities[0];
  }
);
