import * as fromTargetAudiences from './target-audience.reducer';

import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'targetAudience';

export interface TargetAudienceState {
  targetAudience: fromTargetAudiences.TargetAudiencesState;
}

export const reducers: ActionReducerMap<TargetAudienceState> = {
  targetAudience: fromTargetAudiences.TargetAudiencesReducer,
};

export const getLandingState = createFeatureSelector<TargetAudienceState>(featureKey);

export const metaReducers: MetaReducer<TargetAudienceState>[] = !environment.production ? [] : [];
