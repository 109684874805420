import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { Goal, InnovationGoal } from '../../models';
import { MediaComponent } from 'src/app/core/components/media/media.component';
import { IconModule } from '@teamfoster/sdk/icon';
import { Store } from '@ngrx/store';
import { getCookiePreferences } from '@teamfoster/sdk/cookie-ngrx';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';

@Component({
  selector: 'app-goal-expansion-panel',
  standalone: true,
  imports: [MatExpansionModule, MediaComponent, IconModule, DictionaryNgrxModule],
  templateUrl: './goal-expansion-panel.component.html',
  styleUrl: './goal-expansion-panel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoalExpansionPanelComponent {
  @Input({ required: true }) goals: InnovationGoal[] = [];
  @Input({ required: true }) innovationId!: number;

  @Output() activePanelIndexChanged = new EventEmitter<{ id: number; newIndex: number }>();
  @Output() playVideo = new EventEmitter<{ id: number } | undefined>();

  private store = inject(Store);

  cookiePreferences$ = this.store.selectSignal(getCookiePreferences);

  hasContent(goal: InnovationGoal) {
    return goal.youtubeUrl || goal.intro;
  }

  get panelsWithContent() {
    return this.goals.filter(goal => this.hasContent(goal));
  }

  get initialActivePanelId() {
    return this.panelsWithContent[0]?.id || this.goals[0]?.id;
  }
}
