import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Goal } from '../../models';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IconModule } from '@teamfoster/sdk/icon';
import { GoalCheckItemComponent } from '../goal-check-item/goal-check-item.component';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';

@Component({
  selector: 'app-goal-checklist',
  standalone: true,
  imports: [MatTooltipModule, IconModule, GoalCheckItemComponent, DictionaryNgrxModule],
  templateUrl: './goal-checklist.component.html',
  styleUrl: './goal-checklist.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoalChecklistComponent {
  @Input() goals: Goal[] = [];
}
