import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  inject,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { CookieNgrxModule, ShowCookieSettings, getCookiePreferences } from '@teamfoster/sdk/cookie-ngrx';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { IconModule } from '@teamfoster/sdk/icon';
import { Image, ImageModule } from '@teamfoster/sdk/image';
import {
  YoutubeChangeEvent,
  YoutubePlayerComponent,
  YoutubePlayerModule,
  YoutubePlayerVars,
  YoutubeReadyEvent,
  YoutubeState,
} from '@teamfoster/sdk/youtube-player';
import { FadeAnimation } from 'src/app/animations/fade.animation';

@Component({
  selector: 'app-media',
  standalone: true,
  imports: [ImageModule, YoutubePlayerModule, IconModule, CommonModule, DictionaryNgrxModule, CookieNgrxModule],
  templateUrl: './media.component.html',
  styleUrl: './media.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [FadeAnimation],
})
export class MediaComponent implements OnChanges {
  @Input() image?: Image;
  @Input() videoUrl?: string;
  @Input() inView?: boolean = true;

  @Input() captionTitle?: string;
  @Input() captionText?: string;
  @Input() showCaption?: boolean = true;

  @Input() height?: number;
  @Input() width?: number;

  @Input() play?: boolean;

  @Output() videoStopped = new EventEmitter<void>();

  @Input() title?: string;
  @ViewChild('youtube', { static: false }) youtubeComponent?: YoutubePlayerComponent;

  youtubeConfig: YoutubePlayerVars = {
    modestbranding: 1,
  };

  loaded = false;
  state: YoutubeState = -1;
  private store = inject(Store);

  cookiePreferences$ = this.store.selectSignal(getCookiePreferences);

  ngOnChanges(changes: SimpleChanges): void {
    //console.log('changes', changes);
    if (changes['inView'] && this.youtubeComponent?.player) {
      this.youtubeComponent?.player?.pauseVideo();
    }
    if (
      changes['play'] &&
      this.youtubeComponent?.player &&
      changes['play'].currentValue !== changes['play'].previousValue &&
      changes['play'].currentValue === true
    ) {
      this.youtubeComponent?.player?.playVideo();
    }
  }

  get videoAltText() {
    return this.title || this.youtubeComponent?.player?.videoTitle || 'Youtube video';
  }

  getYoutubePoster(): string | undefined {
    if (!this.videoUrl) {
      return;
    }
    const id = this.getVideoId(this.videoUrl);
    if (!id) {
      return;
    }

    return this.getThumbnailUrl(id);
  }

  getVideoId(url: string) {
    // Use a regular expression to extract the video ID from the URL
    const regExp =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regExp);

    return match && match[1] ? match[1] : null;
  }

  getThumbnailUrl(videoId: string) {
    // Build the thumbnail URL with the chosen quality ("maxresdefault" in this case)
    return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
  }

  onPlayerReady(e: YoutubeReadyEvent) {
    this.loaded = true;
  }
  onPlayerChange(e: YoutubeChangeEvent) {
    this.state = e.data as YoutubeState;

    if (this.state === YoutubeState.Paused || this.state === YoutubeState.Ended) {
      this.videoStopped.emit();
    }
  }

  openCookie() {
    this.store.dispatch(ShowCookieSettings());
  }

  playVideo() {
    this.youtubeComponent?.player.playVideo();
  }
}
