<section class="section-inset-y innovations-carousel">
  <div class="innovations-carousel__background"></div>
  <div class="container">
    <div class="position-relative z-2 row">
      <h2 class="text-max-width col-10 col-md-6 offset-1 fw-bolder">{{ 'innovatie-carousel-titel' | fromDictionary }}</h2>
    </div>

    <div class="row innovations-carousel__container">
      <!-- !Gets hidden on mobile screens -->
      <div class="col-md-6 col-lg-5 media-list-container d-none d-md-block" id="media-list-container">
        <div class="media-list-container__inner pull-s">
          <ul class="media-list" [style.--list-total-count]="innovations.length" [class.has--one-item]="hasOneMediaItem(innovations)">
            @for (innovation of innovations; track innovation.id) {
              <li
                class="media-list__item"
                [style.--progress]="scrollProgressArray$()?.[$index]"
                [style.--previous-progress]="$index > 0 ? scrollProgressArray$()?.[$index - 1] : 0"
                [style.--next-progress]="$index < (scrollProgressArray$()?.length || 0) - 1 ? scrollProgressArray$()?.[$index + 1] : 0"
                [style.--list-index]="$index"
                [class.is--inactive]="!mediaItemIsActive($index)"
              >
                @if (innovation.goals[activeInnovationGoalIndexes[$index]?.activeIndex || 0]; as goal) {
                  <!--<pre>{{ goal.title }} - {{ goal.youtubeUrl }}</pre>-->
                  @if ((goal.youtubeUrl || innovation.image?.url) && !triggerFadeAnimation) {
                    <app-media
                      class="media-item"
                      [image]="innovation?.image || { url: innovation.image?.url || '' }"
                      [videoUrl]="goal.youtubeUrl"
                      [inView]="mediaItemIsActive($index)"
                      [captionTitle]="goal.captionTitle"
                      [captionText]="goal.captionText"
                      [showCaption]="
                        (($index < (scrollProgressArray$()?.length || 0) - 1 ? scrollProgressArray$()?.[$index + 1] : 0) || 0) < 50 &&
                        (scrollProgressArray$()?.[$index] || 0) > 90
                      "
                      [width]="1200"
                      [height]="675"
                      [play]="goal.id === playVideoId"
                      (videoStopped)="playVideoId = undefined"
                    ></app-media>
                    <!--[captionTitle]="goal.captionTitle"
                      [captionText]="goal.captionText"-->
                  }
                }
              </li>
            }
          </ul>

          <ul class="nav-dot-list">
            @for (innovation of innovations; track innovation.id) {
              <li class="nav-dot-list__item">
                <button (click)="scrollTo('section-' + innovation.id)" class="nav-dot" [class.is--active]="activeIndex$() === $index">
                  <span class="sr-only">Navigeer naar sectie {{ $index }}</span>
                </button>
              </li>
            }
          </ul>
        </div>
      </div>

      <div class="col-md-6 offset-lg-1">
        @for (innovation of innovations; track innovation.id) {
          <section #section class="section-inset-y innovation-content" [id]="'section-' + innovation.id">
            <header class="innovation-content__header col-10 offset-1 offset-md-1">
              <h2 class="h4 text-primary fw-bolder mb-3">{{ innovation.title }}</h2>

              <div
                class="simple-markdown"
                [innerHTML]="innovation.intro || '' | resizeHtmlImages | stripEmbed: cookiePreferences | safe"
              ></div>
            </header>

            <app-goal-expansion-panel
              class="d-block mt-4"
              [goals]="innovation.goals"
              [innovationId]="innovation.id"
              (activePanelIndexChanged)="handleExpansionPanelChange($event)"
              (playVideo)="handleVideoPlay($event)"
            ></app-goal-expansion-panel>
          </section>
        }
      </div>
    </div>
  </div>
</section>
