import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TargetAudience } from '../models';

@Injectable({
  providedIn: 'root',
})
export class TargetAudienceService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getTargetAudiences() {
    return this.http.get<TargetAudience[]>(`${this.BASE_URL}api/targetAudiences`);
  }
}
