@if (targetAudience || targetAudience$(); as audience) {
  <article class="page" [ngClass]="'theme-' + audience.cssClass">
    <app-target-audience-header
      class="d-block"
      [targetAudience]="audience"
      [isFooter]="isNextAudience"
      [transitioning]="transitioning"
      [ngClass]="'theme' + audience.cssClass"
    >
      <button (click)="nextPage.emit(true)" class="button button--secondary">{{ 'knop-volgende-pagina-label' | fromDictionary }}</button>
    </app-target-audience-header>

    <main *ngIf="!isNextAudience">
      <app-goal-checklist [goals]="audience.goals"></app-goal-checklist>
      <app-innovations-carousel [scrollTop$]="scrollTop$" [innovations]="audience.innovations"></app-innovations-carousel>
    </main>
  </article>
}
