import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-animated-figure',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './animated-figure.component.html',
  styleUrl: './animated-figure.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnimatedFigureComponent {
  @Input({ required: true }) figure!: string;

  stupidSafariAbsolutePath(id: string) {
    return `url(${window.location.href}${id})`;
  }
}
