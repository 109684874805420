import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, Pipe, PipeTransform, inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import Splitting from 'splitting';

@Pipe({
  name: 'splitting',
  standalone: true,
})
export class SplittingPipe implements PipeTransform {
  private platformId = inject(PLATFORM_ID);
  sanitizer = inject(DomSanitizer);

  transform(value: string, by: 'chars' | 'words' | 'lines' = 'lines'): string | SafeHtml {
    if (isPlatformBrowser(this.platformId)) {
      // Splitting.js doesn't support highlighting, so we'll do it ourselves
      // the --char-index is index of $1 within the whole value, excluding the html
      let highlightedValue = value.replace(/\[(.*?)\]/g, (match, p1, offset) => {
        let charIndex = value.slice(0, offset).replace(/<[^>]*>|\[|\]| /g, '').length;
        return `<span class="is--highlighted" style="--char-index:${Math.max(0, charIndex * 0.9)}">${p1}</span>`; // 0.87 i dont know why i need this offset yet.. But it works for now
      });

      let result: string | SafeHtml = Splitting.html({ content: highlightedValue, by });
      result = this.sanitizer.bypassSecurityTrustHtml(result as string);

      return result;
    }

    return value;
  }
}
