<figure class="media">
  @if (image && !videoUrl) {
    <fstr-image [@fade] [image]="image!" [width]="width || 1200" [height]="height || 1000"></fstr-image>
  }

  @if (videoUrl) {
    <div class="video" [@fade]>
      @if (cookiePreferences$()?.marketing) {
        <div class="video__overlay" [class.is--hidden]="state === 1" (click)="playVideo()">
          <img [src]="getYoutubePoster()" [alt]="videoAltText" />

          <!--  TODO: animate button in/out -->
          @if (inView && cookiePreferences$()?.marketing) {
            <button class="button button-icon button--primary button--lg">
              @if (state !== 1 && state !== 3) {
                <fstr-icon anchor="icon-play"></fstr-icon>
              }
              @if (state === 3) {
                <fstr-icon anchor="icon-loading" class="anim--spinning"></fstr-icon>
              }
            </button>
          }
        </div>
      }

      @if (cookiePreferences$()?.marketing) {
        <fstr-youtube-player
          (ready)="onPlayerReady($event)"
          (change)="onPlayerChange($event)"
          #youtube
          [src]="videoUrl"
          [config]="youtubeConfig"
        ></fstr-youtube-player>
      } @else {
        <div class="media-embed-cookie">
          <div class="media-embed-cookie__wrapper">
            <p>
              {{ 'media-no-cookie-bericht' | fromDictionary }}
            </p>
            <button (click)="openCookie()" class="button button--primary button--xs">
              {{ 'cookie-settings' | fromDictionary }}
            </button>
          </div>
        </div>
      }
    </div>
  }

  @if ((captionTitle || captionText) && showCaption) {
    <figcaption class="media-caption font-handwritten">
      <fstr-icon class="media-caption__icon" anchor="icon-arrow-paint"></fstr-icon>

      @if (captionTitle) {
        <span class="text-primary media-caption__title fs-body-lg">{{ captionTitle }}</span>
      }
      @if (captionText) {
        <span class="media-caption__text fs-body-md">{{ captionText }}</span>
      }
    </figcaption>
  }
</figure>
