<header
  class="audience-header section-inset-y"
  (fstrInViewport)="setInView($event)"
  [once]="false"
  [class.is--footer]="isFooter"
  [id]="isFooter ? 'footer-header' : ''"
>
  <div class="container audience-header__inner">
    <div class="row section-inset-y position-relative z-2 audience-header__inner__row">
      @if (!isFooter) {
        <svg
          class="audience-header__circle-accent-dotted"
          [class.rotating]="componentVisible"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 500 500"
          width="500"
          height="500"
        >
          <circle class="dotted-circle" cx="50%" cy="50%" r="50%" fill="none" [ngStyle]="{ transform: 'rotate(' + scroller() + 'deg)' }" />
        </svg>
      }

      <main class="offset-1 col-10 col-lg-6 col-xl-7 audience-header-content">
        <div class="text-max-width">
          @if (targetAudience.title) {
            <span class="h5 fw-bolder d-block mb-3">{{ targetAudience.title }}</span>
          }
          @if (targetAudience.subTitle) {
            <!--[class.text-primary]="isFooter"-->
            <h2 class="h1 text-secondary fw-bolder mb-4">
              {{ targetAudience.subTitle }}
            </h2>
          }

          @if (targetAudience.introText && !isFooter) {
            <p
              class="simple-markdown lead"
              [innerHTML]="targetAudience.introText || '' | resizeHtmlImages | stripEmbed: cookiePreferences | safe"
            ></p>
          }
        </div>
        @if (isFooter) {
          <div class="mt-6">
            @if (!transitioning) {
              <ng-content></ng-content>
            }
            @if (transitioning) {
              <strong>{{ 'pagina-laden-tekst' | fromDictionary }}</strong>
            }
          </div>
        }
      </main>
      <figure class="col-2 col-lg-5 col-xl-4 audience-header-figure">
        <app-animated-figure class="d-block pull-e" [figure]="targetAudience.cssClass"></app-animated-figure>
      </figure>
    </div>
  </div>

  <svg
    class="audience-header__background"
    xmlns="http://www.w3.org/2000/svg"
    width="1440"
    height="1928"
    fill="none"
    viewBox="0 0 1440 1928"
  >
    <g>
      <g filter="url(#blur)"><circle cx="410.323" cy="603.444" r="212.944" fill="#FFEF11" /></g>
      <g filter="url(#blur)">
        <path
          fill="var(--bs-secondary)"
          d="M332.745 799.687c0 147.666-119.707 267.373-267.373 267.373C-82.292 1067.06-202 947.353-202 799.687S-82.293 532.314 65.373 532.314c147.665 0 267.372 119.707 267.372 267.373Z"
        />
      </g>
      <g filter="url(#blur)"><circle cx="971.706" cy="1053.19" r="212.944" fill="var(--bs-secondary)" /></g>
      <g filter="url(#blur)"><circle cx="482.373" cy="1246.87" r="267.373" fill="#C9BB00" fill-opacity=".74" /></g>
    </g>
    <defs>
      <filter id="blur" width="300%" height="300%" x="-100%" y="-100%" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur result="effect1_foregroundBlur_1355_980" stdDeviation="207.1" />
      </filter>
    </defs>
  </svg>
</header>
