import { Component, Input, ChangeDetectorRef, Output, EventEmitter, PLATFORM_ID, inject, effect } from '@angular/core';
import { NavigationStart, Router, RouterModule } from '@angular/router';
import { filter } from 'rxjs/operators';
import * as fromMenu from '@teamfoster/sdk/menu-ngrx';
import { toSignal } from '@angular/core/rxjs-interop';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { SplittingPipe } from '../../pipes/splitting.pipe';
import { TargetAudience } from 'src/app/target-audience/models';
import { CommonModule } from '@angular/common';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { IconModule } from '@teamfoster/sdk/icon';
import { trigger, transition, style, animate } from '@angular/animations';
import { asapScheduler } from 'rxjs';
import { Store } from '@ngrx/store';
import { ShowCookieSettings } from '@teamfoster/sdk/cookie-ngrx';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  standalone: true,
  imports: [DictionaryNgrxModule, SplittingPipe, CommonModule, RouterModule, TextUtilityModule, IconModule],
  animations: [
    //trigger('overlay', [
    //  transition(':enter', [
    //    style({ opacity: 0 }),
    //    animate(
    //      '.2s ease',
    //      style({
    //        opacity: 1,
    //      })
    //    ),
    //  ]),
    //  transition(':leave', [
    //    animate(
    //      '.2s ease',
    //      style({
    //        opacity: 0,
    //      })
    //    ),
    //  ]),
    //]),
  ],
})
export class TopbarComponent {
  private router = inject(Router);

  @Input() loading: boolean = false;
  @Input() menuActive: boolean = false;
  @Input() audiences: TargetAudience[] = [];

  @Input() logoClass: string = ''; // TODO: waar is deze voor?
  //@Input() menu: fromMenu.MenuItem[] = [];

  @Output() toggleOverlay = new EventEmitter<void>();
  @Output() closeOverlay = new EventEmitter<void>();
  @Output() openOverlay = new EventEmitter<void>();

  private routerEvents$ = toSignal(this.router.events.pipe(filter(e => e instanceof NavigationStart)));
  private store = inject(Store);

  routerEffect$ = effect(() => {
    if (this.routerEvents$() instanceof NavigationStart && this.menuActive) {
      asapScheduler.schedule(() => {
        this.closeOverlay.emit();
      });
    }
  });

  openCookie() {
    this.store.dispatch(ShowCookieSettings());
  }
}
