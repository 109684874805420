<article class="block-default">
  <div class="block-default__content">
    @if (block.title) {
      <h2 class="text-light mb-4">{{ block.title }}</h2>
    }
    @if (block.button?.url || block.button?.routerLink) {
      <fstr-link
        [button]="block.button!"
        [cssClasses]="'button button-slanted button--light'"
        >
        @if (!block.button?.text) {
          <span>{{ 'lees-meer' | fromDictionary }}</span>
        }
      </fstr-link>
    }
  </div>
</article>
