import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';

@Component({
  selector: 'app-scroll-indicator',
  standalone: true,
  imports: [DictionaryNgrxModule],
  templateUrl: './scroll-indicator.component.html',
  styleUrl: './scroll-indicator.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollIndicatorComponent {
  @Input() scrollText?: string;
}
