<div class="infinite-scroll-container" [class.is--transitioning]="transitioning">
  <app-target-audience-detail class="d-block" [targetAudience]="targetAudience$()"></app-target-audience-detail>

  <app-scroll-indicator></app-scroll-indicator>
  <div #footer class="section-negative-b" id="footer">
    <app-target-audience-detail
      class="d-block"
      [targetAudience]="nextAudience$()"
      [isNextAudience]="true"
      [transitioning]="transitioning"
      (nextPage)="nextPage()"
    ></app-target-audience-detail>
  </div>
</div>
