<div class="app" [attr.data-bs-theme]="theme$() || ''">
  <div class="app__inner" [class.anim--in]="loading$()">
    @if (loaded$()) {
      <app-topbar
        (toggleOverlay)="toggleMenu()"
        (closeOverlay)="closeMenu()"
        (openOverlay)="openMenu()"
        [menuActive]="menuOpen$()"
        [audiences]="targetAudiences$()"
      ></app-topbar>
    }
    <div class="app__router" [@routerFade]="prepareRoute(outlet)">
      <router-outlet #outlet="outlet"></router-outlet>
    </div>
    <!--@if (loaded$()) {
      <app-footer [domain]="domainName"></app-footer>
    }-->
  </div>
  <app-loader-indicator [loading]="loading$()"></app-loader-indicator>
  @if (loaded$()) {
    <fstr-cookie-notice
      [title]="'cookie-titel' | fromDictionary"
      [text]="'cookie-tekst' | fromDictionary"
      [buttonAccept]="'cookie-accepteer-knop-tekst' | fromDictionary"
      [buttonReject]="'cookie-afwijzen-knop-tekst' | fromDictionary"
    ></fstr-cookie-notice>
  }
  <app-loader-indicator [loading]="loading$()"></app-loader-indicator>
</div>
@if (testGridEnabled) {
  <app-grid-overlay></app-grid-overlay>
}

<svg class="app-bg-noise" preserveAspectRatio="none" viewBox="0 0 800 800" xmlns="http://www.w3.org/2000/svg">
  <filter id="noiseFilter">
    <feTurbulence type="fractalNoise" baseFrequency="0.65" numOctaves="3" stitchTiles="stitch" />
  </filter>

  <rect width="100%" height="100%" filter="url(#noiseFilter)" />
</svg>
