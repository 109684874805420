import { inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/target-audience.action';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { TargetAudienceService } from '../../services';

export const LoadTargetAudiences$ = createEffect(
  (actions$ = inject(Actions), service = inject(TargetAudienceService)) => {
    return actions$.pipe(
      ofType(actions.LoadTargetAudiences),
      switchMap(() =>
        service.getTargetAudiences().pipe(
          map(targetAudience => actions.LoadTargetAudiencesSuccess({ targetAudience })),
          catchError(error => of(actions.LoadTargetAudiencesFail({ error })))
        )
      )
    );
  },
  { functional: true }
);
