import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  QueryList,
  ViewChild,
  ViewChildren,
  WritableSignal,
  computed,
  effect,
  inject,
} from '@angular/core';
import { Innovation, InnovationGoal } from '../../models';
import { MediaComponent } from 'src/app/core/components/media/media.component';
import { GoalExpansionPanelComponent } from '../goal-expansion-panel/goal-expansion-panel.component';
import { CookieNgrxModule, CookiePreferences } from '@teamfoster/sdk/cookie-ngrx';
import { ImageModule } from '@teamfoster/sdk/image';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { FadeAnimation } from 'src/app/animations/fade.animation';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';

@Component({
  selector: 'app-innovations-carousel',
  standalone: true,
  imports: [MediaComponent, GoalExpansionPanelComponent, CookieNgrxModule, ImageModule, TextUtilityModule, DictionaryNgrxModule],
  templateUrl: './innovations-carousel.component.html',
  styleUrl: './innovations-carousel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [FadeAnimation],
})
export class InnovationsCarouselComponent implements OnInit, OnDestroy {
  @Input() innovations: Innovation[] = [];
  @Input() scrollTop$?: WritableSignal<number>;
  @Input() cookiePreferences!: CookiePreferences;

  @ViewChildren('section') sectionElements?: QueryList<ElementRef>;

  //scrollProgressArray = Array.from({ length: this.innovations.length }, () => 0);

  activeInnovationGoalIndexes: { id: number; activeIndex: number }[] = [];
  private platformId = inject(PLATFORM_ID);

  route = inject(ActivatedRoute);

  mql: Partial<MediaQueryList> = this.getMql();

  getMql() {
    if (!isPlatformBrowser(this.platformId)) {
      return { matches: false };
    }
    return window.matchMedia('(min-width: 768px)'); // md breakpoint
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.mql = this.getMql();
  }

  scrollProgressArray$ = computed(() => {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (!this.scrollTop$) {
      return;
    }

    const scrollTop = this.scrollTop$();
    const scrollProgressArray: number[] = [];

    if (!this.mql.matches) {
      return;
    }

    // Loop through each section and calculate scroll progress
    this.sectionElements?.forEach((section, index) => {
      //console.log('scrollTop', section.nativeElement);
      const sectionRect = section.nativeElement.getBoundingClientRect();
      const sectionHeight = sectionRect.height;
      const sectionTop = sectionRect.top + scrollTop - sectionHeight;

      let scrollProgress;

      if (scrollTop >= sectionTop) {
        // If the top of the section is at or above the top of the viewport
        //scrollProgress = Math.min(100, ((scrollTop - sectionTop) / (sectionHeight / 2)) * 100);
        scrollProgress = Math.min(100, ((scrollTop - sectionTop) / sectionHeight) * 100);
      } else {
        // If the top of the section is below the top of the viewport
        scrollProgress = 0;
      }

      scrollProgressArray[index] = scrollProgress;
    });

    // Use normalizedScrollProgress as needed for each section
    //console.log(`Scroll progress for sections:`, scrollProgressArray);
    return scrollProgressArray;
  });

  activeIndex$ = computed(() => {
    const progress = this.scrollProgressArray$();
    return progress?.findIndex((p, index) => this.isActive(index));
  });

  sectionChange$ = effect(() => {
    const sectionChange = this.activeIndex$();
    this.playVideoId = undefined;
  });

  cd = inject(ChangeDetectorRef);
  triggerFadeAnimation = false;
  private _unsubscribe$ = new Subject<void>();

  playVideoId?: number;

  ngOnInit(): void {
    this.activeInnovationGoalIndexes = this.innovations.map(innovation => ({
      id: innovation.id,
      activeIndex: this.activeInnovationGoalIndex(innovation.goals),
    }));

    this.route.fragment.pipe(takeUntil(this._unsubscribe$)).subscribe(fragment => {
      if (fragment) {
        setTimeout(() => {
          this.scrollTo(fragment);
        }, 700);
      }
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  hasOneMediaItem(innovations: Innovation[]) {
    return (
      innovations.reduce((count, innovation) => {
        // Count the image if it exists
        if (innovation.image && innovation.image.url) {
          count++;
        }

        // Count the YouTube URLs
        count += innovation.goals.reduce((goalCount, goal) => (goal.youtubeUrl ? goalCount + 1 : goalCount), 0);

        return count;
      }, 0) === 1
    );
  }

  handleExpansionPanelChange(e: { id: number; newIndex: number }) {
    const i = this.activeInnovationGoalIndexes.findIndex(innovation => innovation.id === e.id);

    if (!this.activeInnovationGoalIndexes[i]) {
      return;
    }

    // If new active index is same as current active index, do nothing
    if (this.activeInnovationGoalIndexes[i].activeIndex === e.newIndex) {
      return;
    }

    // reload video
    if (
      this.activeIndex$() !== undefined &&
      this.innovations[this.activeIndex$()!]?.goals[e.newIndex] &&
      this.innovations[this.activeIndex$()!]?.goals[e.newIndex].youtubeUrl
    ) {
      this.triggerFadeAnimation = true;
    }

    this.activeInnovationGoalIndexes[i].activeIndex = e.newIndex;

    setTimeout(() => {
      this.triggerFadeAnimation = false;
      this.playVideoId = undefined;
      this.cd.detectChanges();
    }, 10);
  }

  handleVideoPlay(e: { id: number } | undefined) {
    this.playVideoId = e?.id || undefined;
  }

  activeInnovationGoalIndex(goals: InnovationGoal[]) {
    const initiallyOpen = goals.findIndex(goal => goal.youtubeUrl);
    return initiallyOpen > -1 ? initiallyOpen : 0;
  }

  scrollTo(section: string) {
    const sectionEl = document.getElementById(section);
    if (!sectionEl) {
      console.log('scroll to element, element not found', section);
    }
    sectionEl?.scrollIntoView({ behavior: 'smooth' });
  }

  // active caroussel item for bullet nav highlight
  isActive(index: number) {
    const visualOffset = 50; // in center of screen
    const progress = this.scrollProgressArray$()?.[index] || 0;
    const nextProgress = this.scrollProgressArray$()?.[index + 1];
    return progress > visualOffset && (nextProgress === undefined || nextProgress < visualOffset);
  }

  mediaItemIsActive(i: number) {
    const threshold = 50;
    const progressOverThreshold = (this.scrollProgressArray$()?.[i] || 0) > threshold;
    const nextProgressWithinThreshold = (this.scrollProgressArray$()?.[i + 1] || 0) < threshold;

    return progressOverThreshold && nextProgressWithinThreshold;
  }
}
