import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TargetAudienceRoutingModule } from './target-audience-routing.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import * as fromStore from './store';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { CoreModule } from '../core/core.module';
import { ImageModule } from '@teamfoster/sdk/image';
import { ButtonModule } from '@teamfoster/sdk/button';
import { IconModule } from '@teamfoster/sdk/icon';
import { YoutubePlayerModule } from '@teamfoster/sdk/youtube-player';
import { ContentBlocksModule } from '../content-blocks/content-blocks.module';
import { MatDialogModule } from '@angular/material/dialog';
import { InViewModule } from '@teamfoster/sdk/in-view';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { ArrayUtilityModule } from '@teamfoster/sdk/array-utility';
@NgModule({
  imports: [
    CommonModule,
    ImageModule,
    ButtonModule,
    TargetAudienceRoutingModule,
    TextUtilityModule,
    CoreModule,
    DictionaryNgrxModule,
    IconModule,
    YoutubePlayerModule,
    ContentBlocksModule,
    MatDialogModule,
    InViewModule,
    MatSnackBarModule,
    ArrayUtilityModule,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers, {
      metaReducers: fromStore.metaReducers,
    }),
    EffectsModule.forFeature(fromStore.effects),
  ],
})
export class TargetAudienceModule {}
