import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, PLATFORM_ID, computed, inject } from '@angular/core';
import * as fromStore from '../../store';
import { Store } from '@ngrx/store';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { TargetAudienceHeaderComponent } from '../../components/target-audience-header/target-audience-header.component';
import { ScrollContainerComponent } from 'src/app/core/components/scroll-container/scroll-container.component';
import { TargetAudience } from '../../models';
import { InnovationsCarouselComponent } from '../../components/innovations-carousel/innovations-carousel.component';
import { GoalChecklistComponent } from '../../components/goal-checklist/goal-checklist.component';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';

@Component({
  selector: 'app-target-audience-detail',
  standalone: true,
  imports: [CommonModule, TargetAudienceHeaderComponent, InnovationsCarouselComponent, GoalChecklistComponent, DictionaryNgrxModule],
  templateUrl: './target-audience-detail.component.html',
  styleUrl: './target-audience-detail.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TargetAudienceDetailComponent extends ScrollContainerComponent {
  private store = inject(Store);
  @Input() targetAudience?: TargetAudience;
  @Input() isNextAudience: boolean = false;
  @Input() transitioning: boolean = false;
  @Output() nextPage = new EventEmitter<boolean>();

  targetAudience$ = this.store.selectSignal(fromStore.getSelectedTargetAudience);
  nextAudience$ = this.store.selectSignal(fromStore.getNextTargetAudience);

  private platformId = inject(PLATFORM_ID);
  override disableScroll = !isPlatformBrowser(this.platformId) || !window.matchMedia('(min-width: 768px)');
}
