import { ChangeDetectionStrategy, Component, Input, computed, effect } from '@angular/core';
import { TargetAudience } from '../../models';
import { ButtonModule } from '@teamfoster/sdk/button';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { AnimatedFigureComponent } from '../animated-figure/animated-figure.component';
import { CookieNgrxModule, CookiePreferences } from '@teamfoster/sdk/cookie-ngrx';
import { ImageModule } from '@teamfoster/sdk/image';
import { CommonModule } from '@angular/common';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { ScrollContainerComponent } from 'src/app/core/components/scroll-container/scroll-container.component';
import { InViewModule } from '@teamfoster/sdk/in-view';

@Component({
  selector: 'app-target-audience-header',
  standalone: true,
  imports: [
    ButtonModule,
    TextUtilityModule,
    AnimatedFigureComponent,
    CookieNgrxModule,
    ImageModule,
    CommonModule,
    DictionaryNgrxModule,
    InViewModule,
  ],
  templateUrl: './target-audience-header.component.html',
  styleUrl: './target-audience-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TargetAudienceHeaderComponent extends ScrollContainerComponent {
  @Input({ required: true }) targetAudience!: TargetAudience;
  @Input() isFooter = false;
  @Input() transitioning = false;
  @Input() cookiePreferences!: CookiePreferences;

  componentVisible = false;
  currentScrollPos = 0;

  scroller = computed(() => {
    const scrollTop = this.scrollTop$();
    // const direction = this.scrollDirection;

    if (!this.componentVisible) {
      return;
    }
    const speed = 360 / 10;
    const rot = (scrollTop / 370) * speed;
    this.currentScrollPos = rot;
    const smoothRot = this.lerp(this.currentScrollPos, rot, 0.05);

    return Math.round(smoothRot);
    // return `${smoothRot}`;
  });

  setInView($e: boolean) {
    this.componentVisible = $e;
  }

  lerp(start: number, end: number, amt: number): number {
    return (1 - amt) * start + amt * end;
  }
}
