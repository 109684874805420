import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, ElementRef, inject, PLATFORM_ID, effect } from '@angular/core';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { IconModule } from '@teamfoster/sdk/icon';
import { Goal } from '../../models';
import { ScrollContainerComponent } from 'src/app/core/components/scroll-container/scroll-container.component';
import { isPlatformBrowser } from '@angular/common';
import { InViewModule } from '@teamfoster/sdk/in-view';
import { debouncedSignal } from 'src/app/core/util/debounceSignal';

@Component({
  selector: 'app-goal-check-item',
  standalone: true,
  imports: [MatTooltipModule, IconModule, InViewModule],
  templateUrl: './goal-check-item.component.html',
  styleUrls: ['./goal-check-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoalCheckItemComponent extends ScrollContainerComponent {
  private platformId = inject(PLATFORM_ID);

  @Input() goal!: Goal;
  @Input() active = false;

  @ViewChild('componentRef', { static: true }) componentRef!: ElementRef;
  @ViewChild(MatTooltip) tooltip!: MatTooltip;

  percentageInView: number = 0;
  inview: boolean = false;
  componentVisible = false;
  showTooltip = true;

  checkHeight = 120;

  animated = false;

  setInView($e: boolean) {
    this.componentVisible = $e;
  }

  debouncedTop$ = debouncedSignal(this.scrollTop$, 200);
  scroller = effect(() => {
    const top = this.debouncedTop$();

    if (!this.componentVisible) {
      return;
    }

    this.tooltip.hide();
    this.calculatePercentageInView();
  });

  calculatePercentageInView() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const component = this.componentRef.nativeElement;
    const rect = component.getBoundingClientRect();
    const viewportHeight = window.innerHeight;

    // Check if the component's top is visible in the viewport
    if (rect.top >= 0 && rect.top <= viewportHeight) {
      const componentHeightInView = Math.min(rect.bottom, viewportHeight / 1.3) - rect.top;
      const componentHeight = rect.height;
      const compFullHeightInView = Math.min(rect.bottom, viewportHeight) - rect.top;
      const percFullView = Math.round((compFullHeightInView / componentHeight) * 100);

      this.percentageInView = Math.round((componentHeightInView / componentHeight) * 100);

      if (this.percentageInView > 90) {
        this.animated = true;
      }

      if (this.percentageInView > 20) {
        this.inview = true;
        this.checkHeight = Math.min(120, 120 - 120 * (this.percentageInView / 100));
      } else {
        //
        this.inview = false;
        this.checkHeight = 120;
      }
    }
  }
}
