import { animate, state, style, transition, trigger } from '@angular/animations';

export const FadeAnimation = trigger('fade', [
  transition(':enter', [
    style({ opacity: 0, position: 'absolute', top: 0, right: 0 }),
    animate(
      '.2s ease',
      style({
        opacity: 1,
      })
    ),
  ]),
  transition(':leave', [
    style({ opacity: 1, position: 'absolute', top: 0, right: 0 }),
    animate(
      '0.2s ease',
      style({
        opacity: 0,
      })
    ),
  ]),
]);

//export const FadeUp = trigger('fadeUp', [
//  transition(':enter', [
//    style({ opacity: 0, transform: 'translateY(10%)' }),
//    animate(
//      '.2s ease',
//      style({
//        opacity: 1,
//        transform: 'translateY(0%)',
//      })
//    ),
//  ]),
//  transition(':leave', [
//    animate(
//      '0.2s ease',
//      style({
//        opacity: 0,
//        transform: 'translateY(10%)',
//      })
//    ),
//  ]),
//]);
