<article class="goal-check-item d-flex" #componentRef (fstrInViewport)="setInView($event)">
  <div class="me-3 goal-checkbox" [class.goal-checkbox--active]="animated">
    <!--<fstr-icon anchor="icon-scratch"></fstr-icon>-->

    <svg class="goal-checkbox__scratch" width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.86035 17.4995L25.9404 7.03662L10.8618 28.2706L33.0183 17.4995L23.171 31.9634L31.4797 28.2706"
        stroke="#FFF500"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
  <div class="goal-check-item__label">
    <span class="goal-check-item__label__name">{{ goal.name }}</span>
    @if (goal.toolTip) {
      <button class="button button-link goal-tooltip" [matTooltip]="goal.toolTip" #tooltip="matTooltip" (click)="tooltip.toggle()">
        <fstr-icon anchor="icon-info"></fstr-icon>
      </button>
    }
  </div>
</article>
