<!-- todo: margin top weghalen -->
<section class="container">
  @if (cookiePreferences$(); as cookies) {
    <ul class="content-blocks row mt-0">
      @for (block of blocks; track block.id) {
        <li
          class="content-blocks__item"
          [ngClass]="'type--' + block.blockType"
          [id]="'blok-' + $index"
          [class.col-md-6]="block.blockType === 'block-image' || block.blockType === 'block-no-image'"
          >
          @switch (block.blockType) {
            <!-- <app-content-block-default [index]="i" [block]="block" *ngSwitchCase="'block-image'"></app-content-block-default> -->
            @case ('block-no-image') {
              <app-content-block-default [index]="$index" [block]="block"></app-content-block-default>
            }
          }
        </li>
      }
    </ul>
  }
</section>
