import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CookieSettingsGuard } from '@teamfoster/sdk/cookie-ngrx';
import { DictionaryGuard } from '@teamfoster/sdk/dictionary-ngrx';
import { MenuGuard } from '@teamfoster/sdk/menu-ngrx';
import { NotFoundComponent } from './404.component';
import { TargetAudiencesGuard } from './target-audience/guards';

const appGuards = [CookieSettingsGuard, DictionaryGuard, MenuGuard, TargetAudiencesGuard];

const routes: Routes = [
  {
    path: 'styleguide',
    loadChildren: () => import('./styleguide/styleguide.module').then(m => m.StyleGuideModule),
    canActivate: [],
  },
  {
    path: 'doelgroepen',
    loadChildren: () => import('./target-audience/target-audience.module').then(m => m.TargetAudienceModule),
    canActivate: [...appGuards],
  },
  {
    path: 'intro',
    loadChildren: () => import('./intro/intro.module').then(m => m.IntroModule),
    canActivate: [...appGuards],
  },
  {
    path: 'pagina',
    loadChildren: () => import('./content/content.module').then(m => m.ContentModule),
    canActivate: [...appGuards],
    //pathMatch: 'full',
  },
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./intro/intro.module').then(m => m.IntroModule),
    canActivate: [...appGuards],
  },
  {
    path: '404',
    component: NotFoundComponent,
    canActivate: [...appGuards],
  },
  {
    path: '**',
    redirectTo: '/404',
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
