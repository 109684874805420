<div class="grid-overlay">
  <div class="container">
    <div class="row grid-showcase">
      @for (number of gridColumns; track number) {
        <div class="col-1">
          <div class="grid-showcase__inner">
            {{ number + 1 }}
          </div>
        </div>
      }
    </div>
  </div>
</div>
<div class="breakpoint-util">
  <ul class="breakpoint-util-list">
    @for (bp of gridBreakpoints; track bp; let i = $index) {
      <li class="breakpoint-util-list__item" [ngClass]="getBpClass(bp, i)">{{ bp }}</li>
    }
  </ul>
</div>
