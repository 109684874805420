<nav class="topbar">
  <div class="topbar__bar">
    <figure class="topbar__logo">
      <a href="/intro/start" class="h-100">
        <img src="/assets/gfx/logo-cibap.svg" alt="" />
      </a>
      <h6 class="topbar__logo__title mb-0">{{ 'logo-titel' | fromDictionary }}</h6>
    </figure>

    <button class="hamburger-button" (click)="toggleOverlay.emit()">
      <span class="fw-bold" *ngIf="!menuActive">{{ 'topbar-menu-knop-tekst' | fromDictionary }}</span>
      <span class="fw-bold" *ngIf="menuActive">{{ 'topbar-menu-knop-tekst-sluiten' | fromDictionary }}</span>
      <div class="hamburger" [class.is--active]="menuActive">
        <!--<fstr-icon anchor="icon-menu-toggle"></fstr-icon>-->
        <svg class="hamburger-button__icon" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.579452 5.56426C0.579452 2.81112 2.81131 0.57926 5.56445 0.57926C8.31759 0.57926 10.5495 2.81112 10.5495 5.56426C10.5495 8.3174 8.31759 10.5493 5.56445 10.5493C2.81131 10.5493 0.579452 8.3174 0.579452 5.56426Z"
            fill="var(--bs-pink)"
          />
          <path
            d="M17.5497 5.56426C17.5497 2.81112 19.7815 0.57926 22.5347 0.57926C25.2878 0.57926 27.5197 2.81112 27.5197 5.56426C27.5197 8.3174 25.2878 10.5493 22.5347 10.5493C19.7815 10.5493 17.5497 8.3174 17.5497 5.56426Z"
            fill="var(--bs-green)"
          />
          <path
            d="M0.579539 22.5342C0.579539 19.7811 2.8114 17.5492 5.56454 17.5492C8.31768 17.5492 10.5495 19.7811 10.5495 22.5342C10.5495 25.2874 8.31768 27.5192 5.56454 27.5192C2.8114 27.5192 0.579539 25.2874 0.579539 22.5342Z"
            fill="var(--bs-primary)"
          />
          <path
            d="M17.5498 22.5342C17.5498 19.7811 19.7816 17.5492 22.5348 17.5492C25.2879 17.5492 27.5198 19.7811 27.5198 22.5342C27.5198 25.2874 25.2879 27.5192 22.5348 27.5192C19.7816 27.5192 17.5498 25.2874 17.5498 22.5342Z"
            fill="var(--bs-blue)"
          />
        </svg>
      </div>
    </button>
  </div>

  @if (menuActive) {
    <!--  [@overlay] -->
    <div class="topbar__overlay">
      <div class="container container-size-lg">
        <section class="topbar__overlay__intro">
          <h6 class="text-primary">{{ 'intro-missie-titel' | fromDictionary }}</h6>
          <div class="text-max-width-sm simple-markdown" [innerHTML]="'intro-missie-tekst' | fromDictionary | splitting"></div>

          <div class="d-flex flex-column flex-md-row align-items-center flex-gap-2 mt-3">
            <span class="fw-bold">{{ 'over-ons-pagina-link-prefix' | fromDictionary }}</span>
            <div class="d-flex flex-gap-2">
              <a class="button button--sm button-outline button--primary" [routerLink]="['/pagina/2/wie-zijn-wij']">
                {{ 'over-ons-pagina-link-tekst' | fromDictionary }}
              </a>
            </div>
          </div>
        </section>

        <ul class="row row-gap-4 audiences">
          @for (audience of audiences; track audience.id) {
            <li class="col-lg-3 col-md-6 audience-group" [ngClass]="'theme-' + audience.cssClass">
              <div>
                <h2 class="text-secondary h6 mb-0">
                  <a [routerLink]="['/doelgroepen', audience.id.toString(), audience.title | urlRewrite]">{{ audience.title }}</a>
                </h2>
                @if (audience.subTitle) {
                  <p class="fw-bold mb-0">{{ audience.subTitle }}</p>
                }
              </div>
              @if (audience.introText) {
                <div class="d-none d-md-block fs-body-xs" [innerHTML]="audience.introText"></div>
              }

              <menu class="audience-group__list">
                @for (innovation of audience.innovations; track innovation.id) {
                  <li class="audience-group__list__item text-secondary fs-body-xs">
                    <fstr-icon anchor="icon-arrow-right"></fstr-icon>
                    <a
                      [routerLink]="['/doelgroepen', audience.id.toString(), audience.title | urlRewrite]"
                      [fragment]="'section-' + innovation.id"
                    >
                      {{ innovation.title }}
                    </a>
                  </li>
                }
              </menu>

              <a
                class="button button--sm button-outline button--secondary audience-group__button"
                [routerLink]="['/doelgroepen', audience.id.toString(), audience.title | urlRewrite]"
              >
                {{ 'menu-pagina-link-tekst' | fromDictionary }}
              </a>
            </li>
          }
        </ul>
      </div>
      <footer class="topbar__footer py-4 py-sm-3">
        <div class="container container-size-lg topbar__footer__inner">
          <button (click)="openCookie()" class="mx-auto ms-md-0 me-auto button-link button--xs cookie-button">
            {{ 'cookie-settings' | fromDictionary }}
          </button>

          <span class="fw-bold">{{ 'download-pdf-tekst' | fromDictionary }}</span>
          <a
            class="button button--sm button-outline button--primary"
            [href]="'doelstellingen-kaart-download-url' | fromDictionary"
            target="_blank"
          >
            {{ 'download-pdf-knop-tekst' | fromDictionary }}
          </a>
        </div>
      </footer>
    </div>
  }
</nav>
