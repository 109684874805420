import { ChangeDetectionStrategy, Component, HostListener, computed, signal } from '@angular/core';

@Component({
  selector: 'app-scroll-container',
  standalone: true,
  imports: [],
  templateUrl: './scroll-container.component.html',
  styleUrl: './scroll-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollContainerComponent {
  scrollTop$ = signal(0);
  scrollFraction$ = signal(0);
  scrollPercentage$ = computed(() => (this.scrollFraction$() * 100 <= 100 ? this.scrollFraction$() * 100 : 100));

  public scrollDirection: 'up' | 'down' = 'up';
  lastScrollTop = 0;

  disableScroll = false;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    this.checkScrollDirection(scrollTop);
    this.scrollFraction$.set(this.getScrollFraction(scrollTop));
    this.scrollTop$.set(window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
  }

  checkScrollDirection(scrollTop: number) {
    this.scrollDirection = scrollTop < this.lastScrollTop ? 'up' : 'down';
    this.lastScrollTop = scrollTop;
  }

  private getScrollFraction(scrollTop: number): number {
    const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
    const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;

    return scrollTop / (scrollHeight - clientHeight);
  }
}
