<mat-accordion class="goal-accordion">
  @for (goal of goals; track goal.id) {
    <!-- TODO: disable using css, because on bigger screens video also gets hidden -->
    <mat-expansion-panel
      [expanded]="initialActivePanelId === goal.id"
      [class.is--disabled]="!hasContent(goal)"
      [class.has-no--text]="!goal.intro"
      [class.is--only-panel-with-content]="panelsWithContent.length === 1 && initialActivePanelId === goal.id"
      (opened)="activePanelIndexChanged.emit({ id: innovationId, newIndex: $index })"
      class="m-0"
      [disabled]="!hasContent(goal)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="bullet-container me-2 me-lg-3">
            <span class="bullet">
              <!--@if (goal.youtubeUrl) {
                <fstr-icon class="bullet__icon" anchor="icon-play"></fstr-icon>
              }-->
            </span>
          </span>
          <span class="h6 lh-1 panel-title__text mb-0 w-100">
            @if (goal.prio) {
              <span class="badge badge--primary">PRIO</span>
            }

            {{ goal.title }}
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="mat-expansion-panel__content">
        @if (goal.youtubeUrl) {
          <app-media
            class="d-md-none expansion-panel-pull-x"
            [videoUrl]="goal.youtubeUrl"
            [captionTitle]="goal.captionTitle"
            [captionText]="goal.captionText"
          ></app-media>
        }
        <div class="fs-body-sm fw-semi simple-markdown" [innerHTML]="goal.intro"></div>

        @if (goal.youtubeUrl && cookiePreferences$()?.marketing) {
          <div class="d-none d-md-block">
            <button class="button button-outline button--sm button--primary" (click)="playVideo.emit({ id: goal.id })">
              <fstr-icon class="me-2" anchor="icon-play"></fstr-icon>
              {{ 'knop-video-afspelen-label' | fromDictionary }}
            </button>
          </div>
        }
      </div>
    </mat-expansion-panel>
  }
</mat-accordion>
