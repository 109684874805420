import { ChangeDetectionStrategy, Component, ElementRef, ViewChild, effect, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { getAllTargetAudiences } from '../../store';
import { TargetAudienceHeaderComponent } from '../../components/target-audience-header/target-audience-header.component';
import { CommonModule } from '@angular/common';
import { ScrollContainerComponent } from 'src/app/core/components/scroll-container/scroll-container.component';
import { TargetAudienceDetailComponent } from '../target-audience-detail/target-audience-detail.component';
import * as fromStore from '../../store';
import { Go } from 'src/app/store';

// Import necessary GSAP modules
import { gsap } from 'gsap';
import { ScrollIndicatorComponent } from 'src/app/core/components/scroll-indicator/scroll-indicator.component';

@Component({
  selector: 'app-infinite-scroll-test',
  standalone: true,
  imports: [CommonModule, TargetAudienceHeaderComponent, TargetAudienceDetailComponent, ScrollIndicatorComponent],
  templateUrl: './infinite-scroll-test.component.html',
  styleUrl: './infinite-scroll-test.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfiniteScrollTestComponent extends ScrollContainerComponent {
  private store = inject(Store);

  targetAudience$ = this.store.selectSignal(fromStore.getSelectedTargetAudience);
  nextAudience$ = this.store.selectSignal(fromStore.getNextTargetAudience);

  @ViewChild('footer', { static: true }) footer?: ElementRef;

  tl = gsap.timeline();

  transitioning = false;
  timeoutId: any;

  isSafari =
    navigator.vendor &&
    navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') == -1 &&
    navigator.userAgent.indexOf('FxiOS') == -1;

  enableAutomaticTransition = !this.isSafari;

  checkforNextAudience = effect(() => {
    const scroll = this.scrollTop$();
    //const footerRect = this.footer?.nativeElement?.getBoundingClientRect();
    const footerPosition = this.footer?.nativeElement?.offsetTop;

    //console.log('scroll', scroll, footerPosition);
    if (scroll > footerPosition - 10 && !this.transitioning && this.enableAutomaticTransition) {
      this.transitionToNextAudience();
    }
  });

  cancelTransition = effect(() => {
    const scroll = this.scrollTop$();
    if (this.scrollDirection === 'up' && this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.transitioning = false;
    }
  });

  transitionToNextAudience() {
    this.transitioning = true;
    console.log('transition to next audience');

    this.timeoutId = setTimeout(() => {
      this.store.dispatch(Go({ path: ['/doelgroepen', this.nextAudience$().id, encodeURI(this.nextAudience$().title)] }));
      this.transitioning = false;
    }, 1000);

    //this.tl.to('#bullshit', {
    //  attr: { position: 'fixed', top: 0, zIndex: 1000 },
    //  duration: 1,
    //  onComplete: () => {
    //    this.store.dispatch(Go({ path: ['/doelgroepen', this.nextAudience$().id, 'test'] }));
    //    this.transitioning = false;
    //    console.log('Page transition complete');
    //  },
    //});
  }

  nextPage() {
    // smooth scroll to bottom of page
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });

    setTimeout(() => {
      this.store.dispatch(Go({ path: ['/doelgroepen', this.nextAudience$().id, encodeURI(this.nextAudience$().title)] }));
    }, 500);
  }
}
