<section class="section-inset-y container goal-checklist">
  <div class="row">
    <div class="col-10 col-md-5 offset-1 py-md-6">
      <h2 class="fw-bolder">{{ 'Doelen-pagina-sectie-doelen-titel' | fromDictionary }}</h2>
    </div>
    <div class="col-10 offset-1 offset-md-0 col-md-6">
      <ul class="ps-md-7 ms-md-4 goal-checklist__list py-md-6">
        @for (goal of goals; track goal.id; let i = $index) {
          <li class="goal-checklist__list__item" [style.--list-index]="i">
            <app-goal-check-item [goal]="goal" [active]="i === 0"></app-goal-check-item>
          </li>
        }
      </ul>
    </div>
  </div>
</section>
