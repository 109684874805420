<!--<p>
  TODO show figure associated with theme:
  <span class="text-primary">{{ figure }}</span>
  with a switch case and inline svg
</p>-->

@switch (figure) {
  @case ('blauw') {
    <svg class="graphic-blauw" xmlns="http://www.w3.org/2000/svg" width="854" height="907" fill="none" viewBox="0 0 854 907">
      <g class="gateway">
        <path
          class="gateway__door"
          stroke="url(#blauw)"
          stroke-width="2"
          d="M554.906 428.221v427.967H108.262V428.221c0-123.337 99.984-223.322 223.322-223.322 123.337 0 223.322 99.985 223.322 223.322Z"
        />

        <path
          class="gateway__door"
          stroke="url(#blauw)"
          stroke-width="2"
          d="M483.582 497.153v358.726h-375.32V497.154c0-103.642 84.018-187.661 187.66-187.661s187.66 84.019 187.66 187.66Z"
        />
        <path
          class="gateway__door"
          stroke="url(#blauw)"
          stroke-width="2"
          d="M415.852 560.716v295.472h-307.59V560.716c0-84.939 68.856-153.796 153.795-153.796s153.795 68.857 153.795 153.796Z"
        />
        <path
          class="gateway__door"
          stroke="url(#blauw)"
          stroke-width="2"
          d="M318.777 653.854v202.333H108.262V653.854c0-58.132 47.125-105.257 105.258-105.257 58.132 0 105.257 47.125 105.257 105.257Z"
        />
      </g>
    </svg>
  }
  @case ('geel') {
    <svg class="graphic-geel" xmlns="http://www.w3.org/2000/svg" width="854" height="907" fill="none" viewBox="0 0 854 907">
      <g class="tunnel">
        <path class="tunnel__square" stroke="url(#geel)" stroke-width="1.251" d="M82.825 377.021h461.643v461.643H82.825z" />
        <path class="tunnel__square" stroke="url(#geel)" stroke-width="1.251" d="M144.376 312.794h461.643v461.643H144.376z" />
        <path class="tunnel__square" stroke="url(#geel)" stroke-width="1.251" d="M206.428 250.74h461.643v461.643H206.428z" />
        <path class="tunnel__square" stroke="url(#geel)" stroke-width="1.251" d="M268.485 189.501h461.643v461.643H268.485z" />
      </g>
      <defs>
        <linearGradient id="geel" x1="260.29" x2="542.411" y1="744.141" y2="340.875" gradientUnits="userSpaceOnUse">
          <stop offset=".38" stop-color="#FFEB00">
            <!--<animate attributeName="offset" values=".2;.38" dur=".8s" repeatCount="1" begin="2s;op.end+2s"></animate>-->
          </stop>
          <stop offset=".693" stop-color="#FFED00">
            <!--<animate attributeName="offset" values=".4;.693" dur=".8s" repeatCount="1" begin="2s;op.end+2s"></animate>-->
          </stop>
          <stop offset=".714" stop-color="#fff">
            <!--<animate attributeName="offset" values=".5;.714" dur=".8s" repeatCount="1" begin="2s;op.end+2s"></animate>-->
          </stop>
          <stop offset="1" stop-color="#030521">
            <!--<animate attributeName="offset" values=".7;1" dur=".8s" repeatCount="1" begin="2s;op.end+2s"></animate>-->
          </stop>
        </linearGradient>
      </defs>
    </svg>
  }
  @case ('magenta') {
    <svg xmlns="http://www.w3.org/2000/svg" width="854" height="907" fill="none" viewBox="0 0 854 907">
      <g class="spring" stroke-width="3">
        <path
          class="spring__circle"
          stroke="url(#magenta)"
          d="M381.969 222.198c2.29 3.966 1.922 9.706-1.62 17.179-3.51 7.405-9.935 16.052-18.861 25.388-17.832 18.654-45.317 39.723-78.236 58.729-32.918 19.006-64.908 32.273-89.978 38.39-12.549 3.061-23.249 4.302-31.418 3.64-8.243-.669-13.397-3.221-15.687-7.186-2.29-3.966-1.922-9.706 1.62-17.179 3.511-7.406 9.936-16.052 18.862-25.389 17.832-18.653 45.316-39.723 78.235-58.728 32.919-19.006 64.908-32.273 89.978-38.39 12.549-3.061 23.25-4.303 31.418-3.64 8.243.669 13.398 3.22 15.687 7.186Z"
        />
        <path
          class="spring__circle"
          stroke="url(#magenta)"
          d="M479 231.117c12.701 21.998 4.677 53.952-20.072 88.36-24.629 34.243-65.365 70.207-116.405 99.675-51.041 29.468-102.554 46.765-144.524 50.973-42.173 4.228-73.857-4.799-86.558-26.798-12.701-21.998-4.677-53.952 20.071-88.36 24.63-34.244 65.366-70.207 116.406-99.675 51.04-29.468 102.553-46.765 144.523-50.973 42.174-4.228 73.858 4.799 86.559 26.798Z"
        />
        <path
          class="spring__circle"
          stroke="url(#magenta)"
          d="M561.188 311.381c14.634 25.347 5.351 62.069-22.996 101.481-28.228 39.246-74.902 80.448-133.367 114.202-58.464 33.755-117.483 53.575-165.585 58.398-48.305 4.843-84.749-5.478-99.383-30.825s-5.351-62.069 22.997-101.481c28.227-39.246 74.901-80.448 133.366-114.202 58.464-33.755 117.483-53.575 165.585-58.398 48.305-4.843 84.749 5.478 99.383 30.825Z"
        />
        <path
          class="spring__circle"
          stroke="url(#magenta)"
          d="M650.377 403.771c16.567 28.695 6.025 70.185-25.921 114.601-31.827 44.249-84.438 90.688-150.327 128.729-65.889 38.041-132.412 60.385-186.647 65.822-54.437 5.459-95.641-6.157-112.208-34.852-16.567-28.695-6.024-70.185 25.922-114.601 31.826-44.249 84.438-90.688 150.327-128.729 65.889-38.041 132.412-60.385 186.646-65.822 54.438-5.459 95.641 6.157 112.208 34.852Z"
        />
        <path
          class="spring__circle"
          stroke="url(#magenta)"
          d="M674.458 507.57c14.633 25.347 5.35 62.069-22.997 101.481-28.228 39.246-74.902 80.448-133.366 114.202-58.465 33.755-117.483 53.575-165.585 58.398-48.306 4.843-84.749-5.479-99.383-30.825-14.634-25.347-5.351-62.069 22.996-101.481 28.228-39.246 74.902-80.448 133.366-114.202 58.465-33.755 117.483-53.575 165.585-58.398 48.306-4.843 84.75 5.478 99.384 30.825Z"
        />
        <path
          class="spring__circle"
          stroke="url(#magenta)"
          d="M702.875 618.88c12.701 21.998 4.677 53.952-20.072 88.361-24.629 34.243-65.365 70.206-116.405 99.674-51.041 29.468-102.554 46.765-144.524 50.973-42.173 4.228-73.857-4.799-86.558-26.798-12.701-21.998-4.677-53.951 20.071-88.36 24.63-34.243 65.366-70.207 116.406-99.675 51.04-29.468 102.553-46.765 144.523-50.973 42.174-4.228 73.858 4.799 86.559 26.798Z"
        />
        <path
          class="spring__circle"
          stroke="url(#magenta)"
          d="M662.266 707.685c2.108 3.652 1.653 9.103-2.033 16.378-3.641 7.185-10.188 15.631-19.219 24.799-18.04 18.313-45.658 39.173-78.584 58.183-32.925 19.009-64.799 32.497-89.679 38.964-12.455 3.237-23.043 4.684-31.086 4.244-8.144-.445-13.091-2.776-15.2-6.429-2.108-3.652-1.654-9.102 2.032-16.377 3.641-7.185 10.189-15.632 19.219-24.799 18.041-18.314 45.659-39.173 78.584-58.183 32.926-19.01 64.8-32.498 89.68-38.964 12.455-3.237 23.043-4.684 31.086-4.245 8.144.445 13.091 2.777 15.2 6.429Z"
        />
      </g>
      <defs>
        <linearGradient id="magenta" x1="10%" x2="50%" y1="80%" y2="100%" gradientUnits="userSpaceOnUse">
          <stop stop-color="#FF018D" />
          <stop offset="0.25" stop-color="#FFED00">
            <!--<animate attributeName="offset" values="0;.25" dur="1.1s" repeatCount="1" begin="2s;op.end+2s" ></animate>-->
          </stop>
          <stop offset="0.7" stop-color="white">
            <!--<animate attributeName="offset" values=".25;.7" dur="1.1s" repeatCount="1" begin="2s;op.end+2s" ></animate>-->
          </stop>
          <stop offset="1" stop-color="#271C22">
            <!--<animate attributeName="offset" values=".7;1" dur="1.1s" repeatCount="1" begin="2s;op.end+2s" ></animate>-->
          </stop>
        </linearGradient>
      </defs>
    </svg>
  }
  @case ('groen') {
    <svg class="graphic-groen" xmlns="http://www.w3.org/2000/svg" width="854" height="907" fill="none" viewBox="0 0 854 907">
      <g class="clover">
        <circle class="clover__leaf" cx="378.479" cy="360.619" r="165.581" stroke="url(#groen)" stroke-width="3.507" />
        <circle class="clover__leaf" cx="545.814" cy="532.505" r="165.581" stroke="url(#groen)" stroke-width="3.507" />
        <circle class="clover__leaf" cx="378.479" cy="695.289" r="165.581" stroke="url(#groen)" stroke-width="3.507" />
        <circle class="clover__leaf" cx="211.145" cy="532.505" r="165.581" stroke="url(#groen)" stroke-width="3.507" />
      </g>
    </svg>
  }
}

<svg class="hide" xmlns="http://www.w3.org/2000/svg" fill="none">
  <defs>
    <linearGradient id="blauw" x1="220.214" x2="643.984" y1="813.547" y2="397.558" gradientUnits="userSpaceOnUse">
      <stop offset=".5" stop-color="#3577AB">
        <!--<animate attributeName="offset" values="0;.5" dur="1.1s" repeatCount="1" begin="2s;op.end+2s"></animate>-->
      </stop>
      <stop offset=".708" stop-color="#FFED00">
        <!--<animate attributeName="offset" values=".2;.708" dur="1.1s" repeatCount="1" begin="2s;op.end+2s"></animate>-->
      </stop>
      <stop offset=".844" stop-color="#fff">
        <!--<animate attributeName="offset" values=".4;.844" dur="1.1s" repeatCount="1" begin="2s;op.end+2s"></animate>-->
      </stop>
      <stop offset="1" stop-color="#040927">
        <!--<animate attributeName="offset" values=".8;1" dur="1.1s" repeatCount="1" begin="2s;op.end+2s"></animate>-->
      </stop>
    </linearGradient>

    <linearGradient
      id="groen"
      x1="295.402"
      x2="499.374"
      y1="505.597"
      y2="214.038"
      gradientUnits="userSpaceOnUse"
      gradientTransform="rotate(5)"
    >
      <!--378.479px 532.505px-->
      <!--<animateTransform
        attributeName="gradientTransform"
        type="rotate"
        from="0 378.479 532.505"
        to="-90 378.479 532.505"
        dur="1.3s"
        repeatCount="1"
        begin="2s;op.end+2s"
        fill="freeze"
      />-->

      <stop offset=".38" stop-color="#80CA6F">
        <!--<animate attributeName="offset" values="0;.38" dur="1.1s" repeatCount="1" begin="2s;op.end+2s"></animate>-->
      </stop>
      <!-- groen -->
      <stop offset=".693" stop-color="#FFED00">
        <!--<animate attributeName="offset" values=".2;.693" dur="1.1s" repeatCount="1" begin="2s;op.end+2s"></animate>-->
      </stop>
      <stop offset=".714" stop-color="#fff">
        <!--<animate attributeName="offset" values=".4;.714" dur="1.1s" repeatCount="1" begin="2s;op.end+2s"></animate>-->
      </stop>
      <stop offset="1" stop-color="#030521">
        <!--<animate attributeName="offset" values=".7;1" dur="1.1s" repeatCount="1" begin="2s;op.end+2s"></animate>-->
      </stop>
    </linearGradient>
  </defs>
</svg>
